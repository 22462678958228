// Interfaces
export interface TrackingInterface {
  logClickEvent: (props: ClickEventProps) => void;
  logPageViewedEvent: (props: PageViewedEventProps) => void;
  logContentViewedEvent: (props: ContentViewedEventProps) => void;
  logSlideEvent: (props: SlideEventProps) => void;
  logStoreSelectedEvent: (props: StoreSelectedEventProps) => void;
  logSearchEvent: (props: SearchEventProps) => void;
  logApplyRedirectEvent: () => void;
  logApplicationProcessEvent: (props: ApplicationProcessEventProps) => void;
  logMerchantLeadEvent: (props: MerchantLeadEventProps) => void;
  logSignAgreementEvent: (props: SignAgreementProps) => void;
  setUserProperties?: (properties: Partial<UserProperties>) => void;
}

export interface TrackingPropsInterface {
  vendor: Vendor;
  onError?: OnErrorFuntionType;
  getSuperEventProps?: GetSuperEventPropsFuntionType;
  getEngagementEventProps?: GetEngagementEventPropsFuntionType;
}

export interface UserProperties {
  snap_user_id: string;
  referer: string;
  feature_flags: string;
}

//classes

export class InvalidPropertiesError extends Error {
  constructor() {
    super('Properties provided are invalid.');
    this.name = 'Invalid Properties';
  }
}

export class FunctionNotInitializedError extends Error {
  constructor(functionName: string) {
    super(`Function: ${functionName} has to be initialized before usage.`);
    this.name = 'Funtion not initialized error.';
  }
}

//types

export interface Vendor {
  logEvent: LogEventFuntionType;
  setUserProperties: SetUserPropertiesFuntionType;
}

export type ClickType =
  | 'cta'
  | 'link'
  | 'button'
  | 'dropdown'
  | 'radio button'
  | 'checkbox';

export type ApplicationProcessEventName =
  | 'application started'
  | 'application submitted'
  | 'application status changed'
  | 'send application link';

export type SystemEventName = 'log' | 'error';

export type EventName =
  | 'click'
  | 'page viewed'
  | 'content viewed'
  | 'slide'
  | 'search'
  | 'store selected'
  | 'apply redirect'
  | 'merchant lead created'
  | 'person_id available'
  | 'sign origination agreement'
  | SystemEventName
  | ApplicationProcessEventName;

export type ClickLocation = 'map' | 'list';

export type SearchType = 'keyword' | 'location';

export type Placement =
  | 'body'
  | 'footer'
  | 'header'
  | 'modal'
  | 'top navigation'
  | 'request details modal'
  | 'new merchant application modal'
  | 'hero'
  | 'SSR prerendered apply button'
  | 'calculator'
  | string;

export type platform =
  | 'consumer application'
  | 'mobile application'
  | 'customer portal';

export type LeadSourceType =
  | 'com partner page long form'
  | 'com partner page short form'
  | 'com partner page slice form';

export type EventErrorType = 'InvalidEvent' | 'InvalidProperties';

export interface SuperEventProps {
  analytics_session_storage: string;
  analytics_session_cookie: string;
  analytics_uuid: string;
}

export interface EngagementEventProps {
  page_url: string;
  page_path: string;
  subdomain_and_domain: string;
}

export type ApplicationSource =
  | 'SNAP_FINANCE_COM'
  | 'MERCHANT'
  | 'INTERNET'
  | 'REMARKET'
  | 'KIOSK'
  | 'SMS'
  | 'STORIS'
  | 'ECOMM_BANNER'
  | 'SHORT_CODE'
  | 'ECOMM_XO'
  | 'QR_CODE'
  | 'ECOMM_API'
  | string;

//types per function

export interface AppProcessEventProps {
  product: 'LTO' | 'SLN';
  snap_source?: ApplicationSource;
  merchant_id?: string;
  agreement_type?: string;
  application_id?: string;
  status?: string;
  sub_status?: string;
  pre_status?: string;
  experience_name?: string;
  origination_id?: string;
  orig_channel?: string;
}

export interface SignAgreementProps {
  process: 'origination agreement';
  funded_amount: number;
  merchant_name: string;
  agreement_type: 'RIC' | 'TAB' | 'BP1' | string;
  product: 'LTO' | 'SLN';
  application_id: string;
  merchant_id: string;
  agreement_id: string;
  snap_source: ApplicationSource;
}

export interface ClickEventProps {
  click_type: ClickType;
  placement: Placement;
  click_value: string;
}

export interface PageViewedEventProps {
  page_name?: string;
}

export interface ContentViewedEventProps {
  content_name: string;
  content_value: string;
}

export interface SlideEventProps {
  slider_name: string;
  slider_value: string;
}

export interface StoreSelectedEventProps {
  click_location: ClickLocation;
  store_name: string;
  industry: string;
}

export interface SearchEventProps {
  search_type: SearchType;
  search_term: string;
}

export interface MerchantLeadEventProps {
  lead_source: LeadSourceType;
  lead_id: string;
  business_name: string;
}

export interface PersonAvailableEventProps {
  platform: platform;
}

export interface ApplicationProcessEventProps {
  event_name: ApplicationProcessEventName;
  event_properties: AppProcessEventProps;
}

export type LogEventFuntionType = (
  event_name: EventName | string,
  event_properties?: object
) => void;

export type SetUserPropertiesFuntionType = (
  properties: Partial<UserProperties>
) => void;

export type GetSuperEventPropsFuntionType = () => SuperEventProps | object;

export type GetEngagementEventPropsFuntionType = () => EngagementEventProps | object;

export type OnErrorFuntionType = (error: Error) => void
