var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"r02dFzwwvgtbpB5EIc1bq"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable node/no-process-env */
import * as Sentry from '@sentry/nextjs';
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
if (
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'training' ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'sandbox' ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
) {
  Sentry.init({
    // https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
    // defaultIntegrations: false, // disable default integrations, remove this line if want to capture errors by default
    // integrations: [new Sentry.Integrations.HttpContext()],
    integrations: function (integrations) {
      // integrations will be all default integrations
      return integrations.filter(function (integration) {
        return ![
          'TryCatch',
          'GlobalHandlers',
          'Replay',
          'CaptureConsole',
          'HttpClient',
        ].includes(integration.name);
      });
    },
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    dsn: SENTRY_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    sampleRate: 0.25,
    beforeSend(event, hint) {
      if (
        /.*\/api\/merchant\/merchantGroup.*404.*/.test(
          hint.originalException.toString()
        )
      ) {
        return null;
      }
      event.tags = {
        ...event.tags,
        page_path: window.location.pathname,
      };
      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
