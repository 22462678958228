export const appConfig = {
  isProduction: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production',
  customerPortalUrl: process.env.NEXT_PUBLIC_URL_CUSTOMER_PORTAL,
  merchantPortalUrl: process.env.NEXT_PUBLIC_URL_MERCHANT_PORTAL,
  storeLocatorApi: process.env.NEXT_PUBLIC_API_STORE_LOCATOR,
  storeLocatorApiExperimental:
    process.env.NEXT_PUBLIC_API_STORE_LOCATOR_EXPERIMENTAL,
  storeLocatorApiExperimentalApiKey:
    process.env.NEXT_PUBLIC_API_STORE_LOCATOR_EXPERIMENTAL_API_KEY,
  snapAppApi: process.env.NEXT_PUBLIC_API_SNAPAPP,
  snapAppInternalApi: process.env.NEXT_PUBLIC_API_INTERNAL,
  mapboxApiAccessToken: process.env.NEXT_PUBLIC_TOKEN_MAPBOX_ACCESS,
  mapboxMapStyleUrl: process.env.NEXT_PUBLIC_URL_MAPBOX_STYLE,
  siteUrl: process.env.NEXT_PUBLIC_URL_SITE,
  comUrl: process.env.NEXT_PUBLIC_COM_URL || '',
  supportDomainUrl: process.env.NEXT_PUBLIC_SUPPORT_DOMAIN,
  storeLocatorEndpoint: process.env.STORE_LOCATOR_ENDPOINT,
  applyEndpoint: process.env.NEXT_PUBLIC_URL_APPLY,
  jobOpeningsEndpoint: process.env.NEXT_PUBLIC_URL_JOB_OPENINGS_SCRIPT,
  productMS: process.env.NEXT_PUBLIC_URL_PRODUCT_MS,
  merchantOnboardingEndpoint: process.env.NEXT_PUBLIC_URL_MERCHANT_ONBOARDING,
  paymentCalculatorEndpoint: process.env.NEXT_PUBLIC_URL_API_PAYMENT_CALCULATOR,
  useGTM: process.env.USE_GTM === 'true',
  useTrackJS: process.env.USE_TRACK_JS === 'true',
  useUIEventJSProduction:
    process.env.NEXT_PUBLIC_USE_UI_EVENT_JS_PRODUCTION === 'true',
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  unoptimizedImages: !!process.env.NEXT_PUBLIC_UNOPTIMIZED_IMAGES === true,
  yelpApi: process.env.NEXT_PUBLIC_YELP_API,
  yelpApiToken: process.env.NEXT_PUBLIC_YELP_API_KEY,
  splitApi: process.env.NEXT_PUBLIC_KEY_SPLIT,
  awsRegion: process.env.AWS_REGION,
  merchantYelpRefDynamoDBTable: process.env.MERCHANT_YELP_REF_DYNAMODB_TABLE,
  isStorybook: process.env.STORYBOOK === 'true',
  appStoreMobileAppDownloadLink: 'https://www.apple.com',
  googleStoreMobileAppDownloadLink:
    'https://play.google.com/store/apps/details?id=com.appster.payix.snapfinance&hl=es_419&gl=US',
  mobileLandingPage: '/mobile-app',
  unOptimizedImages: !!process.env.NEXT_PUBLIC_UNOPTIMIZED_IMAGES === true,
  disableReduxMiddleware:
    process.env.NEXT_PUBLIC_DISABLE_REDUX_MIDDLEWARE === 'true',
  enableFindPagesBySlice: process.env.ENABLE_FIND_PAGES_BY_SLICE === 'true',
  snapMobileAppId: '8080609414',
  googleOptimizeContainerId: process.env.GOOGLE_OPTIMIZE_CONTAINER_ID,
  legacyApplyUrlForAcquisitions: process.env.NEXT_PUBLIC_APPLY_URL,
  platformApiForAcquisitions: process.env.NEXT_PUBLIC_PLATFORM_API,
  snapLambdasTokenForAcquisitions: process.env.NEXT_PUBLIC_SNAP_LAMBDAS_TOKEN,
  enableKameleoon: process.env.NEXT_PUBLIC_ENABLE_KAMELEOON !== 'false',
  hotjarSideId: 3206089,
  hotjarVersion: 6,
  enableThreatMetrix: process.env.ENABLE_THREATMETRIX === 'true',
  merchantRetrievalLimit: 20,
  uniquePersonToolingApi: process.env.NEXT_PUBLIC_UNIQUE_PERSON_TOOLING_API,
  addressValidationApi: process.env.NEXT_PUBLIC_API_ADDRESS_VALIDATOR,
  enableFingerprintJS: process.env.NEXT_PUBLIC_FINGERPRINTJS_ENABLED === 'true',
  enableOldStoreLocator: false,
};
